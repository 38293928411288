import { ResetPassword, ResetPasswordRequest } from '@services/auth-api'
import { useMutation } from '@tanstack/vue-query'

import { ServiceError } from '@epostbox/shared/errors'

export function useResetPassword() {
  const { mutateAsync, data, error, ...mutation } = useMutation<ResetPassword, ServiceError, ResetPasswordRequest>({
    mutationFn: async variables => {
      const { response, data, error } = await ResetPassword({
        body: variables,
      })
      if (!response.ok) {
        throw ServiceError.fromResponse(error)
      }
      return data!
    },
  })

  return { resetPassword: mutateAsync, result: data, error, ...mutation }
}
