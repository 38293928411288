<script lang="ts">
import { type InjectionKey } from 'vue'

import { formItemVariants, FormItemProps } from './'

export const FORM_ITEM_INJECTION_KEY = Symbol() as InjectionKey<string>
</script>

<script lang="ts" setup>
import { provide } from 'vue'
import { useId } from 'radix-vue'
import { cn } from '../../utils'

const id = useId()
defineProps<FormItemProps>()
provide(FORM_ITEM_INJECTION_KEY, id)
</script>

<template>
  <div :class="cn(formItemVariants({ orientation }), $attrs.class ?? '')">
    <slot />
  </div>
</template>
