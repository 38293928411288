import { cva, type VariantProps } from 'class-variance-authority'

export { Form } from 'vee-validate'
export { default as FormField } from './form-field.vue'
export { default as FormItem } from './form-item.vue'
export { default as FormLabel } from './form-label.vue'
export { default as FormControl } from './form-control.vue'
export { default as FormMessage } from './form-message.vue'
export { default as FormDescription } from './form-description.vue'
export { default as PreviewEditForm } from './preview-edit-form-item.vue'
export { default as PreviewEditFormInput } from './preview-edit-form-item-input.vue'
interface ErrorMessageWithVariables {
  message: string
  variables: Record<string, string>
}

export type ErrorMessage = ErrorMessageWithVariables | string

export const formItemVariants = cva('', {
  variants: {
    orientation: {
      vertical: 'flex flex-col',
      horizontal: 'flex gap-x-4 justify-between',
    },
  },
  defaultVariants: {
    orientation: 'vertical',
  },
})

type FormItemVariants = VariantProps<typeof formItemVariants>
export interface FormItemProps {
  required?: boolean
  orientation?: FormItemVariants['orientation']
}
