<script setup lang="ts">
import {
  TooltipContent,
  type TooltipContentEmits,
  type TooltipContentProps,
  TooltipPortal,
  useForwardPropsEmits,
  TooltipArrow,
} from 'radix-vue'

import { cn } from '../../utils'

const props = withDefaults(defineProps<TooltipContentProps & { arrowClasses?: string }>(), {
  sideOffset: 4,
  arrowClasses: '',
})
const emits = defineEmits<TooltipContentEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <TooltipPortal :force-mount="true">
    <TooltipContent
      v-bind="{ ...forwarded, ...$attrs }"
      :class="
        cn(
          'z-[99] max-w-48 overflow-hidden rounded bg-gray-1000 px-3 py-2 text-xs font-semibold text-white animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          $attrs.class ?? ''
        )
      "
    >
      <slot />
      <TooltipArrow :width="7" :height="3" :class="cn('transition-all duration-75', arrowClasses)" />
    </TooltipContent>
  </TooltipPortal>
</template>
