<script setup lang="ts">
import AuthWrapper from '@components/common/auth-wrapper.vue'
import CheckEmailForgotPassword from '@components/forgot-password/check-email-forgot-password.vue'
import ForgotPassword from '@components/forgot-password/forgot-password.vue'
import { ref } from 'vue'

interface ForgotPasswordSuccess {
  email?: string
}

const step = ref(1)
const email = ref<string>()
const onSuccess = (value: ForgotPasswordSuccess) => {
  email.value = value.email
  step.value = 2
}
</script>

<template>
  <AuthWrapper>
    <ForgotPassword v-if="step == 1" @success="onSuccess" />
    <CheckEmailForgotPassword v-else :email="email" />
  </AuthWrapper>
</template>
