<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import AlertCircle from '~icons/epostbox/alert-circle'
import ArrowLeft from '~icons/epostbox/arrow-left'

import { Button } from '@epostbox/ui/button'
import { Heading } from '@epostbox/ui/heading'
import { Paragraph } from '@epostbox/ui/paragraph'
import { toast } from '@epostbox/ui/sonner'

import { useForgotPassword } from '@composables/use-forgot-password'

const props = defineProps<{ email?: string }>()
const { t } = useI18n()

const { forgotPassword } = useForgotPassword()
const resendEmail = async () => {
  if (!props.email) return
  await forgotPassword({ email: props.email })
  toast.info(t('auth.flow.forgot_password.check_email.toast.title'), {
    description: t('auth.flow.forgot_password.check_email.toast.description'),
    icon: AlertCircle,
    dataE2e: 'resend-toast',
  })
}
</script>

<template>
  <div>
    <Heading size="2xl" class="mb-3" align="center">{{ $t('auth.flow.forgot_password.check_email.title') }}</Heading>
    <Paragraph class="mb-8 flex flex-col" align="center">
      {{ $t('auth.flow.forgot_password.check_email.description') }}
      <span class="font-medium" data-e2e="checked-email"> {{ email }} </span>
    </Paragraph>

    <Paragraph size="sm" class="mb-8 flex items-center justify-center gap-1" align="center">
      {{ $t('auth.flow.forgot_password.check_email.not_received_1') }}
      <Button
        variant="transparent"
        size="sm"
        type="submit"
        class="w-max"
        data-e2e="forgot-password-email-not-received"
        @click="resendEmail"
      >
        {{ $t('auth.flow.forgot_password.check_email.not_received_2') }}
      </Button>
    </Paragraph>

    <Button as="router-link" to="/" variant="link" size="md" class="mx-auto w-max" data-e2e="login">
      <ArrowLeft class="mr-1.5 h-5 w-5" />
      {{ $t('auth.button.back') }}
    </Button>
  </div>
</template>
