<script setup lang="ts">
import PasswordChecks from '@components/common/password-checks.vue'
import Strength from '@components/strength.vue'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { useRoute } from 'vue-router'
import ArrowLeft from '~icons/epostbox/arrow-left'

import { Alert } from '@epostbox/ui/alert'
import { Button } from '@epostbox/ui/button'
import { Card, CardContent } from '@epostbox/ui/card'
import { FormField } from '@epostbox/ui/form'
import { Heading } from '@epostbox/ui/heading'
import { Input } from '@epostbox/ui/input'
import { Paragraph } from '@epostbox/ui/paragraph'

import { Register, useParseEmail } from '@composables/schemas'
import { useResetPassword } from '@composables/use-reset-password'

const route = useRoute()
const { parseEmail } = useParseEmail()

const form = useForm({
  validationSchema: toTypedSchema(Register),
  initialValues: {
    email: parseEmail(route.query.email as string),
    password: undefined,
    repeatPassword: '',
  },
})

const emit = defineEmits<{
  success: void[]
}>()

const { resetPassword, error, isPending } = useResetPassword()

const onSubmit = form.handleSubmit(async ({ password }) => {
  await resetPassword(
    {
      token: String(route.query.token),
      newPassword: password,
    },
    {
      onSuccess() {
        emit('success')
      },
    }
  )
})
</script>
<template>
  <div class="m-auto w-full max-w-sm px-3">
    <Heading size="2xl" class="mb-3" align="center">{{ $t('auth.flow.reset_password.title') }}</Heading>
    <Paragraph class="mb-8 flex flex-col" align="center">
      {{ $t('auth.flow.reset_password.description') }}
    </Paragraph>
    <Card>
      <CardContent class="px-5 py-6">
        <Alert v-if="error" variant="destructive" class="mb-4" :description="$t(`common.error.${error.name}`)" />
        <form novalidate class="flex w-full flex-col justify-between" @submit.prevent="onSubmit">
          <div>
            <FormField v-slot="{ field }" :form="form" name="password">
              <Input type="password" :label="$t('auth.field.password.label')" autocomplete="password" v-bind="field" />
            </FormField>
            <FormField v-slot="{ field }" :form="form" name="repeatPassword">
              <Input
                type="password"
                :label="$t('auth.field.confirmPassword.label')"
                autocomplete="repeat-password"
                v-bind="field"
              />
            </FormField>
            <Strength />
            <PasswordChecks />
          </div>
          <div class="mt-6">
            <Button
              variant="primary"
              type="submit"
              size="lg"
              class="w-full"
              :disabled="isPending"
              data-e2e="reset-password"
            >
              {{ $t('auth.button.reset_password') }}
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
    <Button as="router-link" to="/" variant="link" size="md" class="mx-auto mt-8 w-max" data-e2e="login">
      <ArrowLeft class="mr-1.5 h-5 w-5" />
      {{ $t('auth.button.back') }}
    </Button>
  </div>
</template>
