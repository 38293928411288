<script lang="ts" setup>
import { cn } from '../../utils'

import { useFormField } from './use-form-field'

const { formDescriptionId } = useFormField()
</script>

<template>
  <p :id="formDescriptionId" :class="cn('text-sm text-gray-600 font-normal mt-1.5', $attrs.class ?? '')">
    <slot />
  </p>
</template>
