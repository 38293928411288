<script setup lang="ts">
import { usePasswordFeedback } from '@composables/use-password-strength'

import FeedbackItem from './feedback-item.vue'

const { feedback } = usePasswordFeedback()
</script>

<template>
  <div v-if="feedback" class="mt-6 flex flex-col gap-3">
    <FeedbackItem v-if="feedback.warning" type="warning" :message="feedback.warning" />

    <div v-if="feedback.suggestions?.length" class="flex flex-col gap-3">
      <FeedbackItem v-for="(item, i) in feedback.suggestions" :key="`${item}-${i}`" type="suggestion" :message="item" />
    </div>
  </div>
</template>
