<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import ArrowLeft from '~icons/epostbox/arrow-left'

import { Alert } from '@epostbox/ui/alert'
import { Button } from '@epostbox/ui/button'
import { Card, CardContent } from '@epostbox/ui/card'
import { FormField } from '@epostbox/ui/form'
import { Heading } from '@epostbox/ui/heading'
import { Input } from '@epostbox/ui/input'
import { Paragraph } from '@epostbox/ui/paragraph'

import { Email, EmitSuccessEmail } from '@composables/schemas'
import { useForgotPassword } from '@composables/use-forgot-password'

const form = useForm({
  validationSchema: toTypedSchema(Email),
  initialValues: {
    email: undefined,
  },
})

const emit = defineEmits<EmitSuccessEmail>()

const { forgotPassword, error, isPending } = useForgotPassword()

const onSubmit = form.handleSubmit(async values => {
  await forgotPassword(values)
  if (!error.value) {
    emit('success', {
      email: values.email,
    })
  }
})
</script>

<template>
  <div class="m-auto w-full max-w-sm px-3">
    <Heading size="2xl" class="mb-3" align="center">{{ $t('auth.flow.forgot_password.title') }}</Heading>
    <Paragraph class="mb-8 flex flex-col" align="center">
      {{ $t('auth.flow.forgot_password.description') }}
    </Paragraph>
    <Card>
      <CardContent class="px-5 py-6">
        <Alert v-if="error" variant="destructive" class="mb-4" :description="$t(`common.error.${error.name}`)" />
        <form novalidate @submit.prevent="onSubmit">
          <FormField v-slot="{ field }" :form="form" name="email" data-e2e="field-validation">
            <Input type="email" :label="$t('auth.field.email.label')" autocomplete="email" v-bind="field" />
          </FormField>
          <div class="pt-2">
            <Button
              variant="primary"
              type="submit"
              size="lg"
              class="w-full"
              :loading="isPending"
              data-e2e="forgot-password"
            >
              {{ $t('auth.button.reset_password') }}
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
    <Button as="router-link" to="/" variant="link" size="lg" class="mx-auto mt-8 w-max" data-e2e="login">
      <ArrowLeft class="mr-1.5 h-5 w-5" />
      {{ $t('auth.button.back') }}
    </Button>
  </div>
</template>
