import { ForgotPassword, ForgotPasswordRequest } from '@services/auth-api'
import { useMutation } from '@tanstack/vue-query'

import { ServiceError } from '@epostbox/shared/errors'

export function useForgotPassword() {
  const {
    data,
    mutateAsync: forgotPassword,
    error,
    ...mutation
  } = useMutation<ForgotPassword, ServiceError, ForgotPasswordRequest>({
    mutationFn: async variables => {
      const { response, data, error } = await ForgotPassword({
        params: {
          query: variables,
        },
      })
      if (!response.ok || !data) {
        throw ServiceError.fromResponse(error)
      }
      return data
    },
  })

  return { forgotPassword, result: data, error, ...mutation }
}
