<script lang="ts" setup>
import { toValue, ref, computed, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

import { useFormField } from './use-form-field'

import { type ErrorMessage } from '.'

const { name, formMessageId, error } = useFormField()
const { t } = useI18n()

const errorMessage = ref<ErrorMessage>(error.value)
watchEffect(() => {
  errorMessage.value = error.value
})
const message = computed(() => {
  if (typeof errorMessage.value === 'object') {
    return t(errorMessage.value.message, errorMessage.value.variables ?? {})
  }
  return t(errorMessage.value)
})
</script>

<template>
  <p
    v-if="errorMessage"
    :id="formMessageId"
    as="p"
    :name="toValue(name)"
    class="text-xs font-semibold leading-4.5 text-error-650"
  >
    {{ message }}
  </p>
</template>
