<script setup lang="ts">
import PasswordChecks from '@components/common/password-checks.vue'
import TermsCheckbox from '@components/common/terms-checkbox.vue'
import Strength from '@components/strength.vue'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { ref } from 'vue'
import ArrowLeft from '~icons/epostbox/arrow-left'

import { Alert } from '@epostbox/ui/alert'
import { Button } from '@epostbox/ui/button'
import { Card, CardContent } from '@epostbox/ui/card'
import { FormField } from '@epostbox/ui/form'
import { Heading } from '@epostbox/ui/heading'
import { Input } from '@epostbox/ui/input'
import { Paragraph } from '@epostbox/ui/paragraph'

import { Register, EmitSuccessEmail } from '@composables/schemas'
import { useRegister } from '@composables/use-register'

const form = useForm({
  validationSchema: toTypedSchema(Register),
  initialValues: {
    email: undefined,
    password: undefined,
    repeatPassword: '',
  },
})

const terms = ref(false)

const emit = defineEmits<EmitSuccessEmail>()

const { register, error, isPending } = useRegister()

const onSubmit = form.handleSubmit(async ({ email, password }) => {
  await register({
    email,
    password,
  })
  if (!error.value) {
    emit('success', { email })
  }
})
</script>

<template>
  <div class="m-auto w-full max-w-sm px-3">
    <Heading size="2xl" class="mb-3" align="center">{{ $t('auth.flow.login.title') }}</Heading>
    <Paragraph class="mb-8 flex flex-col" align="center">
      {{ $t('auth.flow.register.description') }}
    </Paragraph>
    <Button as="router-link" to="/sso" variant="social" size="lg" class="mb-8 w-full" data-e2e="sso">
      {{ $t('auth.button.sso') }}
    </Button>
    <Card>
      <CardContent class="px-5 py-6">
        <Alert v-if="error" variant="destructive" class="mb-4" :description="$t(`common.error.${error.name}`)" />
        <form novalidate @submit.prevent="onSubmit">
          <FormField v-slot="{ componentField }" :form="form" name="email" data-e2e="email-validation">
            <Input type="email" :label="$t('auth.field.email.label')" autocomplete="email" v-bind="componentField" />
          </FormField>
          <FormField v-slot="{ field }" :form="form" name="password" data-e2e="field-required">
            <Input type="password" :label="$t('auth.field.password.label')" autocomplete="password" v-bind="field" />
          </FormField>
          <FormField v-slot="{ field }" :form="form" name="repeatPassword" data-e2e="password-match-validation">
            <Input
              type="password"
              :label="$t('auth.field.repeatPassowrd.label')"
              autocomplete="repeat-password"
              v-bind="field"
            />
          </FormField>
          <Strength />
          <PasswordChecks />
          <TermsCheckbox v-model="terms" class="mt-6" />

          <Button
            variant="primary"
            type="submit"
            size="lg"
            class="w-full"
            :disabled="isPending || !terms"
            data-e2e="register"
          >
            {{ $t('auth.button.register') }}
          </Button>
          <Button
            as="router-link"
            to="/forgot-password"
            variant="link"
            size="md"
            type="submit"
            class="mx-auto mt-4 flex w-max items-center justify-center"
            data-e2e="forgot-password"
          >
            {{ $t('auth.button.forgot_password') }}
          </Button>
        </form>
      </CardContent>
    </Card>
    <Button as="router-link" to="/" variant="link" size="md" class="mx-auto mt-8 w-max" data-e2e="login">
      <ArrowLeft class="mr-1.5 h-5 w-5" />
      {{ $t('auth.button.back') }}
    </Button>
  </div>
</template>
