import { cva } from 'class-variance-authority'
import { AlertCircle, CheckCircle2, AlertTriangle, Info } from 'lucide-vue-next'

export { default as Alert } from './alert.vue'
export { default as AlertTitle } from './alert-title.vue'
export { default as AlertDescription } from './alert-description.vue'

export const alertVariants = cva(
  'relative flex items-start gap-x-4 w-full rounded-xl border p-4 text-sm [&>svg]:rounded-full',
  {
    variants: {
      variant: {
        default: 'bg-background text-foreground',
        destructive: 'bg-error-50 border-error-300 [&>svg]:text-error-600 [&>svg]:bg-error-200',
        success: 'bg-success-50 border-success-300 [&>svg]:text-success-600 [&>svg]:bg-success-200',
        warning: 'bg-warning-50 border-warning-300 [&>svg]:text-warning-600 [&>svg]:bg-warning-200',
        info: 'bg-brand-50 border-brand-300 [&>svg]:text-brand-600 [&>svg]:bg-brand-200',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export const icon = {
  default: Info,
  destructive: AlertCircle,
  success: CheckCircle2,
  warning: AlertTriangle,
  info: Info,
}
