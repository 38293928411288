import { AuthAPIClient, InferBody, InferRequest, InferResponse } from '@epostbox/auth-api/client'

const Auth = AuthAPIClient({
  baseUrl: `${location.origin}${import.meta.env.DEV ? '/api' : ''}`,
  credentials: 'include',
})

export const AuthorizeURL = Auth.getUrl('/api/authorize')

export const Register = Auth.post('/api/flow/register')
export type Register = InferResponse<typeof Register>
export type RegisterRequest = InferBody<typeof Register>

export const Login = Auth.post('/api/flow/login')
export type Login = InferResponse<typeof Login>
export type LoginRequest = InferBody<typeof Login>

export const LoginSAML = Auth.post('/api/flow/saml')
export type LoginSAML = InferResponse<typeof LoginSAML>
export type LoginSAMLRequest = InferBody<typeof LoginSAML>

export const ForgotPassword = Auth.get('/api/flow/password')
export type ForgotPassword = InferResponse<typeof ForgotPassword>
export type ForgotPasswordRequest = InferRequest<typeof ForgotPassword>['params']['query']

export const ResetPassword = Auth.put('/api/flow/password')
export type ResetPassword = InferResponse<typeof ResetPassword>
export type ResetPasswordRequest = InferBody<typeof ResetPassword>
