<script setup lang="ts">
import { cn } from '../../utils'

import { AlertTitle, AlertDescription } from '.'
import { alertVariants, icon } from '.'

interface Props {
  variant?: NonNullable<Parameters<typeof alertVariants>[0]>['variant']
  class?: string
  title?: string
  description?: unknown
}

const props = defineProps<Props>()
</script>

<template>
  <div :class="cn(alertVariants({ variant }), props.class ?? '')" data-e2e="alert-component">
    <component :is="icon[variant!]" v-if="icon[variant!]" class="h-8 w-8 min-w-8 p-1.5" />
    <div class="flex w-full items-center justify-between gap-x-4">
      <div>
        <AlertTitle v-if="title" class="text-sm font-semibold text-gray-900">
          {{ title }}
        </AlertTitle>
        <AlertDescription v-if="description" class="font-normal text-gray-700">
          {{ description }}
        </AlertDescription>
      </div>
      <slot name="actions" />
    </div>
  </div>
</template>
