import { Register, RegisterRequest } from '@services/auth-api'
import { useMutation } from '@tanstack/vue-query'

import { ServiceError } from '@epostbox/shared/errors'

export function useRegister() {
  const { mutateAsync, data, error, ...mutation } = useMutation<Register, ServiceError, RegisterRequest>({
    mutationFn: async variables => {
      const { response, data, error } = await Register({
        body: variables,
      })
      if (!response.ok || !data) {
        throw ServiceError.fromResponse(error)
      }
      return response
    },
  })

  return { register: mutateAsync, result: data, error, ...mutation }
}
