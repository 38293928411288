import { LoginSAML, LoginSAMLRequest } from '@services/auth-api'
import { useMutation } from '@tanstack/vue-query'

import { ServiceError } from '@epostbox/shared/errors'

export function useSaml() {
  const { data, mutateAsync, error, ...mutation } = useMutation<LoginSAML, ServiceError, LoginSAMLRequest>({
    mutationFn: async variables => {
      const { response, data, error } = await LoginSAML({
        body: variables,
      })
      if (!response.ok) {
        throw ServiceError.fromResponse(error)
      }
      return data!
    },
    onSuccess: result => {
      window.open(result.redirectUrl, '_self')
    },
  })

  return { login: mutateAsync, result: data, error, ...mutation }
}
