import { Login, LoginRequest } from '@services/auth-api'
import { useMutation } from '@tanstack/vue-query'

import { ServiceError } from '@epostbox/shared/errors'

export function useLogin() {
  const { data, mutateAsync, error, ...mutation } = useMutation<Login, ServiceError, LoginRequest>({
    mutationFn: async variables => {
      const { response, data, error } = await Login({
        body: variables,
      })
      if (!response.ok || !data) throw ServiceError.fromResponse(error)
      return data
    },
    onSuccess: result => {
      if (result.redirectUrl.includes('tauri')) {
        window.open(result.redirectUrl)
        // todo: show the user to open the app
      } else {
        location.replace(result.redirectUrl)
      }
    },
  })

  return { login: mutateAsync, result: data, error, ...mutation }
}
