<script setup lang="ts">
import AuthWrapper from '@components/common/auth-wrapper.vue'
import ResetPasswordSuccess from '@components/reset-password/reset-password-success.vue'
import ResetPassword from '@components/reset-password/reset-password.vue'
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

onMounted(() => {
  if (!route.query.token) {
    location.replace('/')
  }
})

const step = ref(1)
const onSuccess = () => {
  step.value = 2
}
</script>

<template>
  <AuthWrapper>
    <ResetPassword v-if="step == 1" @success="onSuccess" />
    <ResetPasswordSuccess v-else />
  </AuthWrapper>
</template>
