<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { z } from 'zod'

import { useZod } from '@epostbox/shared/zod'
import { Toaster } from '@epostbox/ui/sonner'
import { TooltipProvider } from '@epostbox/ui/tooltip'

const { errorMap } = useZod()
// @ts-expect-error some types are wrong but it works
z.setErrorMap(errorMap)
const { t } = useI18n()

const route = useRoute()

watchEffect(() => {
  const title = route?.meta?.title
  useHead({
    title: `${t(`page_title.auth.${title}`)} | epostbox`,
  })
})
</script>

<template>
  <TooltipProvider>
    <Toaster rich-colors position="bottom-center" close-button />
    <RouterView />
  </TooltipProvider>
</template>
