<script setup lang="ts">
import type { CheckboxRootEmits, CheckboxRootProps } from 'radix-vue'
import { CheckboxIndicator, CheckboxRoot, useEmitAsProps } from 'radix-vue'
import Check from '~icons/epostbox/check'
import Minus from '~icons/epostbox/indeterminate'

import { cn } from '../../utils'

const props = defineProps<CheckboxRootProps>()
const emits = defineEmits<CheckboxRootEmits>()

const emitsAsProps = useEmitAsProps(emits)
</script>

<template>
  <CheckboxRoot
    v-bind="{ ...props, ...emitsAsProps }"
    :class="
      cn(
        'peer h-5 w-5 shrink-0 appearance-none rounded border border-gray-475 bg-white !ring-brand-550 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-brand-550 data-[state=indeterminate]:border-gray-475 data-[state=checked]:bg-brand-550 data-[state=indeterminate]:bg-gray-475 data-[state=checked]:text-white data-[state=indeterminate]:text-white',
        $attrs.class ?? ''
      )
    "
  >
    <CheckboxIndicator class="flex items-center justify-center text-current">
      <Minus v-if="checked === 'indeterminate'" class="h-4 w-4 text-white" />
      <Check v-if="checked === true" class="h-4 w-4 text-white" />
    </CheckboxIndicator>
  </CheckboxRoot>
</template>
